import { LOCALES } from "../locales";

export default {
  [LOCALES.ENGLISH]: {
    Welcome: "Welcome",
    "Log in": "Log in",
    Username: "Username",
    Password: "Password",
    Login: "Login",
    "Forgot Password?": "Forgot Password?",
    Orders: "Orders",
    Outlets: "Outlets",
    Billing: "Billing",
    Temperature: "Temperature",
    "Sign out": "Sign out",
    "Take Order": "Take Order",
    "Select Outlet": "Select Outlet",
    "Start Billing": "Start Billing",
    "No Orders!": "No Orders!",
    "Please select an order": "Please select an order",
    "Please select an outlet!": "Please select an outlet!",
    "Search Outlets": "Search Outlets",
    "Total Sale": "Total Sale",
    "Total Orders": "Total Orders",
    "Opening Time": "Opening Time",
    "Closing Time": "Closing Time",
    "Search Products or Tags": "Search Products or Tags",
    "Food type wise filter": "Food type wise filter",
    "Spice wise filter": "Spice wise filter",
    "Allergies food wise filter": "Allergies food wise filter",
    "Sync Menu": "Sync Menu",
    "Customer details": "Customer details",
    SearchOrders: "Search orders",
    "Customer Name": "Customer Name",
    Availability: "Availability",
    Website: "Website",
    Recieved: "Recieved",
    Accepted: "Accepted",
    Prepared: "Prepared",
    Settled: "Settled",
    Cancelled: "Cancelled",
    "Coupon Code": "Coupon Code",
    "Kitchen instructions": "Kitchen instructions",
    "Delivery instructions": "Delivery instructions",
    Subtotal: "Subtotal",
    Discount: "Discount",
    "taxable value": "taxable value",
    "Packaging charges": "Packaging charges",
    "Delivery charges": "Delivery charges",
    Total: "Total",
    Bill: "Bill",
    Settle: "Settle",
    Place: "Place",
    Delivery: "Delivery",
    TakeAway: "TakeAway",
    "Dine in": "Dine in",
    "Search Products or Tags": "Search Products or Tags",
    "Food type wise filter": "Food type wise filter",
    "Spice wise filter": "Spice wise filter",
    "Allergies food wise filter": "Allergies food wise filter",
    "Sync Menu": "Sync Menu",
    "Customer details": "Customer detail",
  },
};
