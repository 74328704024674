import { LOCALES } from "../locales";

export default {
  [LOCALES.JAPANESE]: {
    Welcome: "ようこそ",
    "Log in": "ログイン",
    Username: "ユーザー名",
    Password: "パスワード",
    Login: "ログイン",
    "Forgot Password?": "パスワードをお忘れの方",

    Orders: "注文",
    Outlets: "店舗",
    Billing: "請求",
    Temperature: "気温",
    "Sign out": "ログアウト",
    "Take Order": "注文を受ける",
    "Select Outlet": "店舗を選ぶ",
    "Start Billing": "注文書を作成",
    "No Orders!": "注文がありません",
    "Please select an order": "注文を選んでください",
    "Please select an outlet!": "店舗を選んでください",
    "Search Outlets": "店舗を検索",
    "Total Sale": "売上高",
    "Total Orders": "注文数",
    "Opening Time": "開店時間",
    "Closing Time": "閉店時間",
    "Search Products or Tags": "商品やタグを検索",
    "Food type wise filter": "商品フィルター",
    "Spice wise filter": "調味料フィルター",
    "Allergies food wise filter": "アレルギー食材フィルター",
    "Sync Menu": "メニュー同期",
    "Customer details": "顧客詳細",
    SearchOrders: "注文を検索",
    "Customer Name": "顧客名",
    Availability: "利用可能",
    Website: "ウェブサイト",
    Recieved: "受注",
    Accepted: "確認済み",
    Prepared: "完成",
    Settled: "確定済み",
    Cancelled: "キャンセル済み",
    "Coupon Code": "クーポンコード",
    "Kitchen instructions": "キッチンの備考",
    "Delivery instructions": "配達の備考",
    Subtotal: "小計",
    Discount: "割引",
    "taxable value": "消費税",
    "Packaging charges": "梱包料",
    "Delivery charges": "配達料",
    Total: "合計",
    Bill: "請求書発行",
    Settle: "支払方法",
    Place: "登録",
    Delivery: "配達",
    TakeAway: "テイクアウト",
    "Dine in": "店内",
    "Search Products or Tags": "商品やタグを検索",
    "Food type wise filter": "商品フィルター",
    "Spice wise filter": "調味料フィルター",
    "Allergies food wise filter": "アレルギー食材フィルター",
    "Sync Menu": "メニュー同期",
    "Customer details": "顧客詳細",
    KOT: "注文票"
  },
};
